import React, { Component, useEffect, useRef, useState } from "react"
import { GetMembership, GetProfile } from "../Utils/ReactQueries"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import Checkbox from "../Styling/Checkbox"
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { patchApi, postApi } from "../Utils/WebApi-utils"
import { toast } from "react-toastify"
// import { fetchApi, isNullOrUndefined, patchApi } from "../Common/WebApi-utils"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest, msalConfig } from "../Security/authConfig"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { Loading } from "../Utils/Loading"
import { Controller, useForm } from "react-hook-form"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"
import { resetWarningCache } from "prop-types"
import { navigate } from "gatsby-link"
import LoadingModal from "../Modal/LoadingModal"
import WrongAgeModal from "../Modal/WrongAgeModal"
import { authToken } from "../Security/authToken"
import {
  inputField,
  inputFieldError,
  inputFieldPhoneNumber,
  inputFieldPhoneNumberError,
} from "../Styling/InputField"
import PhoneInput from "react-phone-input-2"
import { CountriesToValidate } from "../Utils/CountriesToValidate"
import { validatePhoneMiniUser } from "../Utils/Utils"
import { left, right, saveButton } from "../Styling/Buttons"
import axios from "axios"
import { CheckAge } from "../Common/Methods"

const queryClient = new QueryClient()

export type Order = {
  PriceLevelId: string
  Name: string
  CustomerId: string
  Source: string
  SignUpSource: string | null | undefined
  OrderLines: OrderLines[]
}
export type OrderLines = {
  Unit: string
  Quantity: number
  UserId: string
  ProductNumber: string
}

const CreateHefSubscriptionContent = props => {
  const { instance, accounts, inProgress } = useMsal()
  const [subConfirmed, setSubConfirmed] = useState(false)
  const queryClient = useQueryClient()
  const inputRef = useRef({})
  const [validPhone, setValidPhone] = useState(false)
  const [unsubscribeCheck, setUnsubscribeCheck] = useState(false)
  const [memberShipCheck, setMemberShipCheck] = useState(true)
  const [loadState, setLoadState] = useState(false)
  const [signupsource, setSignupsource] = useQueryParam("grunn", StringParam)

  const [huAge, setHuAge] = useState(false)
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useForm()
  const [checkRequired, setCheckRequired] = useState(true)

  const userProfile = GetProfile(accounts, inProgress, instance)
  const [phoneValue, setPhoneValue] = useState(userProfile?.data?.mobilePhone)
  const [mobileDefaultValue, setMobileDefaultValue] = useState(null)
  const [firstNameDefaultValue, setFirstNameDefaultValue] = useState(null)
  const [lastNameDefaultValue, setLastNameDefaultValue] = useState(null)
  const [emailDefaultValue, setEmailDefaultValue] = useState(null)
  const [postalCodeDefaultValue, setPostalCodeDefaultValue] = useState(null)
  const [vippsFlow, setVippsFlow] = useState(false)
  const [consent, setConsent] = useState(false)

  const tzOffset = new Date().getTimezoneOffset() * 60000
  const localTime = new Date(Date.now() - tzOffset).toISOString()
  const today = localTime

  const membership = GetMembership(accounts, inProgress, instance)

  // Check age if it is HU membership

  useEffect(() => {
    if (membership?.data == undefined) {
      membership.refetch()
      // setMemberShipCheck(false)
    } else if (
      // (
      // (props.typeMembership === "huinn" &&
      //   membership?.data?.huMember === true) ||
      props.typeMembership === "hefinn" &&
      membership?.data?.hefMember === true
    ) {
      // ||
      //   (props.typeMembership === "huinn" &&
      //     membership?.data?.hefMember === true &&
      //     (userProfile?.data?.age < 15 || userProfile?.data?.age > 26))
      // )
      navigate("/")
    } else {
      setMemberShipCheck(false)
    }
  }, [
    membership?.data,
    membership.isSuccess,
    userProfile?.data?.age != undefined,
  ])

  useEffect(() => {
    if (userProfile.isSuccess && userProfile?.data == null) {
      userProfile.refetch()
    }
  }, [userProfile.isSuccess])

  const PostOrder = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefSalesOrder/Order",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        navigate("/velkommen")
        setLoadState(false)
      },
      onError: data => {
        console.log(data)
        toast.error("Noe gikk galt med orderen!")
      },
    }
  )

  useEffect(() => {}, [mobileDefaultValue])

  useEffect(() => {
    setFirstNameDefaultValue(userProfile?.data?.firstName)
    setLastNameDefaultValue(userProfile?.data?.lastName)
    setEmailDefaultValue(userProfile?.data?.email)
    setPostalCodeDefaultValue(userProfile?.data?.postalCode)
    if (
      mobileDefaultValue == null &&
      userProfile?.data?.mobilePhone != undefined
    ) {
      setMobileDefaultValue(userProfile?.data?.mobilePhone)
      setPhoneValue(userProfile?.data?.mobilePhone)
    }
    if (
      accounts[0]?.idTokenClaims["idp"] == "Vipps" &&
      userProfile?.data?.postalCode !== undefined
    ) {
      setVippsFlow(true)
    }

    reset()
  }, [userProfile?.data])

  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        queryClient.invalidateQueries(["profile"])
      },
      onError: data => {
        toast.error("Noe gikk galt!")
      },
    }
  )
  let orderObject: Order | any = {
    PriceLevelId: process.env.GATSBY_APP_ORDER_PRICELEVEL!,
    Name:
      process.env.GATSBY_APP_ORDER_NAME_HEF + userProfile?.data?.customerNumber,
    CustomerId: userProfile.data?.id,
    Source: props?.source,
    SignUpSource: signupsource,
    OrderLines: [
      {
        Unit: process.env.GATSBY_APP_ORDERLINE_UNIT!,
        Quantity: 1,
        UserId: userProfile.data?.id,
        ProductNumber: "HEF",
      },
    ],
  }

  const onSubmitInfo = values => {
    setLoadState(true)
    values.mobilePhone = phoneValue
    if (
      (values.mobilePhone == undefined ||
        values.mobilePhone == "" ||
        values.mobilePhone.length <= 3) &&
      (values.email == undefined || values.email == "")
    ) {
      setValidPhone(false)
      // errors.forEach(({ name, type, message }) =>
      //   setError(name, { type, message })
      // )
      setError("email", {
        message: "Ugyldig nummer",
      })
      setError("mobilePhone", {
        message: "Ugyldig nummer",
      })

      return toast.error("Skriv inn mobilnummer eller e-post")
    }
    if (validPhone === false) {
      return toast.error("Mobilnummer er ugyldig")
    }

    if (values.mobilePhone == undefined || values.mobilePhone.length < 3) {
      values.mobilePhone = ""
    } else if (values.mobilePhone.length == 12) {
      values.mobilePhone = values.mobilePhone
    } else {
      values.mobilePhone = "+" + values.mobilePhone
    }
    if (values.mobilePhone) {
    }
    values.modifiedByCustomer = today

    setSubConfirmed(true)
    UpdateUser.mutate(values)
    PostOrder.mutate(orderObject)
  }

  useEffect(() => {
    if (
      userProfile.data?.email?.length == 0 &&
      userProfile.data?.mobilePhone?.length == 0
    ) {
      setCheckRequired(true)
    } else if (validPhone || userProfile?.data?.email?.length > 0) {
      setCheckRequired(false)
    }
  }, [inputRef.current["email"]])

  return (
    <div>
      <div className="lg:flex lg:justify-center md:flex md:justify-center">
        {loadState ? (
          <LoadingModal />
        ) : (
          <div className="px-8 pb-16 pt-8 lg:w-1/2  lg:flex lg:flex-col md:w-2/3 md:flex md:flex-col items-center">
            {/* {huAge ? <WrongAgeModal /> : null} */}

            <div className="lg:justify-center md:justify-center lg:w-full">
              {userProfile.isLoading ? (
                <LoadingModal />
              ) : (
                <form
                  name="contact"
                  method="patch"
                  onSubmit={handleSubmit(onSubmitInfo)}
                  className="w-full justify-center"
                >
                  <h1 className="text-xl font-serif text-blue justify-self-center mb-12">
                    Hvordan kan vi kontakte deg, {firstNameDefaultValue}
                  </h1>
                  <div className="flex flex-col space-y-7 justify-between ">
                    <div>
                      {userProfile?.data?.socialSecurityNumber ==
                      undefined ? null : (
                        <label className="font-sans text-base">
                          Du må fylle ut alle feltene for å gå videre.
                        </label>
                      )}
                    </div>
                    {/* Email */}
                    <div>
                      <label className="text-base text-blue font-sans">
                        E-post
                      </label>
                      <div className="relative">
                        {errors.email && (
                          // <label className="font-sans text-orange-dark mt-3 absolute inset-y-0 right-0 p-2 pr-4 invisible sm:visible ">
                          <label className="font-sans text-orange-dark mt-3 absolute inset-y-0 right-0 p-2 pr-4 ">
                            Ugyldig e-post
                          </label>
                        )}
                        <input
                          type="Email"
                          ref={value => (inputRef.current["email"] = value)}
                          id="floatField"
                          className={
                            errors.email ? inputFieldError : inputField
                          }
                          placeholder="E-post"
                          defaultValue={emailDefaultValue}
                          {...register("email", {
                            pattern:
                              /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        ></input>
                      </div>
                    </div>
                    {/* Phonenumber */}
                    <div>
                      <label className="text-base text-blue font-sans">
                        Mobilnummer
                      </label>
                      <div className="relative">
                        <div
                          className={
                            errors.mobilePhone
                              ? inputFieldPhoneNumberError
                              : inputFieldPhoneNumber
                          }
                        >
                          <Controller
                            name="mobilePhone"
                            control={control}
                            defaultValue={mobileDefaultValue}
                            render={({ field: { onChange, value, name } }) => (
                              <PhoneInput
                                autoFormat={false}
                                inputStyle={{
                                  maxHeight: "52px",
                                  width: "100%",
                                  border: "0px",
                                  borderRadius: "0px",
                                  boxShadow: "0px",
                                  color: "black",
                                  fontSize: "18px",
                                }}
                                country={"no"}
                                inputProps={{
                                  name: name,
                                }}
                                onlyCountries={CountriesToValidate}
                                value={phoneValue}
                                onChange={e => setPhoneValue(e)}
                                isValid={inputNumber =>
                                  validatePhoneMiniUser(
                                    inputNumber,
                                    setValidPhone
                                  )
                                }
                              />
                            )}
                          />
                          {(errors.mobilePhone || validPhone === false) && (
                            <label className="font-sans text-orange-dark flex items-center absolute  inset-y-0 right-0 p-2 pr-4 ">
                              Ugyldig telefonnummer
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Postal code */}
                    <div>
                      <label className="text-base text-blue font-sans">
                        Postnummer
                      </label>
                      <div className="relative">
                        {errors.postalCode && (
                          <label className="font-sans text-orange-dark mt-3 absolute inset-y-0 right-0 p-2 pr-4 invisible sm:visible ">
                            Ugyldig postnummer
                          </label>
                        )}
                        <input
                          type="text"
                          inputMode="numeric"
                          maxLength={4}
                          className={
                            errors.postalCode ? inputFieldError : inputField
                          }
                          placeholder="Postnummer"
                          defaultValue={userProfile.data?.postalCode}
                          required
                          {...register("postalCode", {
                            maxLength: 4,
                            minLength: 4,
                            required: true,
                          })}
                        ></input>
                      </div>
                      {errors.postalCode && (
                        <label className="font-sans text-orange-dark p-2 pr-4 justify-end visible flex sm:hidden">
                          Ugyldig postnummer
                        </label>
                      )}
                    </div>
                    {/* Checkbox for accepting HEF terms */}
                    <div className="flex flex-row">
                      <div>
                        <Checkbox
                          defaultChecked={false}
                          onClick={e => {
                            setConsent(e)
                          }}
                        />
                      </div>
                      <p className="text-xs text-black ml-2 font-sans mb-0">
                        Jeg aksepterer at Human-Etisk Forbund behandler mine
                        personopplysninger i tråd med{" "}
                        <a
                          href="https://www.human.no/om-oss/personvernerklaring"
                          className="text-blue underline"
                        >
                          personvernerklæringen
                        </a>
                      </p>
                    </div>
                    {/* Button */}
                    <div className="w-full grid grid-cols-2 gap-4 items-center ">
                      <div className="col-start-1 col-span-1" />
                      <button
                        type="submit"
                        disabled={!consent}
                        className={
                          (consent ? "text-blue " : "text-gray ") +
                          " flex justify-self-end text-xs"
                        }
                      >
                        Bli medlem nå
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-1 h-5 w-5 text-xl font-bold font-serif transition-all -rotate-90 "
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      {/* Validation errors */}
                      {(errors.lastName ||
                        errors.email ||
                        errors.mobilePhone ||
                        errors.postalCode) && (
                        <label className="font-sans text-orange-dark mt-2">
                          Du har glemt å fylle inn noen felter
                        </label>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
const CreateHefSubscription = props => {
  const [auth, setAuth] = useQueryParam("auth", StringParam)
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const membership = GetMembership(accounts, inProgress, instance)

  const authRequest = {
    ...loginRequest,
    clientId: process.env.GATSBY_APP_AAD_APPLICATION_ID,
    authority:
      process.env.GATSBY_APP_AAD_B2C_INSTANCE +
      process.env.GATSBY_APP_AAD_B2C_TENTANT +
      "/" +
      process.env.GATSBY_APP_AAD_B2C_SIGNINGPOLICY,
    validateAuthority: false,
    knownAuthorities: [process.env.GATSBY_APP_AAD_B2C_KNOWN_AUTHORITIES],
    redirectUri: process.env.GATSBY_APP_BASE_URL + props.typeMembership,

    extraQueryParameters: {
      option: auth,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  }
  useEffect(() => {
    const token = authToken(accounts, inProgress, instance)
    if (isAuthenticated) {
      if (
        accounts[0]?.idTokenClaims["idp"] != "Vipps" &&
        accounts[0]?.idTokenClaims["idp"] != "BankId"
      ) {
        navigate("/verifisering")
      }
    }
  }, [isAuthenticated])
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      // loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <CreateHefSubscriptionContent {...props} />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}
export default CreateHefSubscription
