import React, { useState } from "react"
import CreateHefSubscription from "../../components/MyPage/CreateHEFSubscription"

const Subscription = () => {
  const props = {
    unit: process.env.GATSBY_APP_ORDERLINE_UNIT,
    priceLevel: process.env.GATSBY_APP_ORDER_PRICELEVEL,
    name: process.env.GATSBY_APP_ORDER_NAME_HEF,
    orderLine: process.env.GATSBY_APP_ORDERLINE_HEF_SUB,
    typeMembership: "hefinn",
    source: "Magasin",
    productNumber: "HEF",
  }

  return <CreateHefSubscription {...props} />
}

export default Subscription
